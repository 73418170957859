<template>
    <!-- 预约拍照下半部分组件 -->
    <div class="photograph_bottom_box">
        <!-- 标题 -->
        <div class="phototheme_title">
            <span>{{title.title}}</span>
        </div>
        <!-- 副标题 -->
        <div class="phototheme_subtitle">{{title.subtitle}}</div>
        <!-- 主题选择区域 -->
        <el-row :gutter="20" v-if="goods.length">
            <el-col :span="8">
                <router-link :to="{name: 'photographDetail', query: {id: goods[0].id}}">
                    <el-card :body-style="{ padding: '0px' }" shadow="hover">
                        <div class="info_box">
                            <!-- 图片 -->
                            <img :src="require('@/assets/images/photograph/'+goods[0].img)" alt="">
                            <!-- 标题区域 -->
                            <div class="info_text_area">
                                <!-- 标题 -->
                                <div class="info_title">{{goods[0].title}}</div>
                                <!-- 副标题 -->
                                <div class="info_subtitle">{{goods[0].subtitle}}</div>
                            </div>
                            <!-- 价格 -->
                            <div class="price">¥{{goods[0].price}}</div>
                            <!-- 热门 -->
                            <div class="phototheme_hot" v-if="goods[0].hot==1">
                                <img :src="require('@/assets/images/photograph/@1x/icon_hot.png')" alt="">
                            </div>
                        </div>
                    </el-card>
                </router-link>
            </el-col>
            <el-col :span="16">
                <el-row :gutter="20">
                    <template v-for="(item,index) of goods">
                        <el-col 
                            :span="12" 
                            :key="index"
                            v-if="index>0 && index<5">
                            <router-link :to="{name: 'photographDetail', query: {id: item.id}}">
                                <el-card :body-style="{ padding: '0px' }" shadow="hover">
                                    <div class="info_box">
                                        <!-- 图片 -->
                                        <img :src="require('@/assets/images/photograph/'+item.sm_img)" alt="">
                                        <!-- 标题区域 -->
                                        <div class="info_text_area">
                                            <!-- 标题 -->
                                            <div class="info_title">{{item.title}}</div>
                                            <!-- 副标题 -->
                                            <div class="info_subtitle">{{item.subtitle}}</div>
                                        </div>
                                        <!-- 价格 -->
                                        <div class="price">¥{{item.price}}</div>
                                        <!-- 热门 -->
                                        <div class="phototheme_hot" v-if="item.hot==1">
                                            <img :src="require('@/assets/images/photograph/@1x/icon_hot.png')" alt="">
                                        </div>
                                    </div>
                                </el-card>
                            </router-link>
                        </el-col>
                    </template>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    props:["title","goods"]
}
</script>

<style scoped>
/* element ui */
.photograph_bottom_box .el-col{
    margin-bottom: 15px;
}

/* 下半部分盒子 */
.photograph_bottom_box{
    margin-top: 70px;
    text-align: center;
}
/* 标题 */
.photograph_bottom_box>.phototheme_title>span{
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #E6A23C;
    border-bottom-width:medium;
}
/* 副标题 */
.photograph_bottom_box>.phototheme_subtitle{
    color: rgb(145, 145, 145);
    margin: 20px 0;
}
/* 内容盒子 */
.photograph_bottom_box .info_box{
    position: relative;
}
/* 图片样式 */
.photograph_bottom_box .info_box>img{
    width: 100%;
}
/* 标题区域 */
.photograph_bottom_box .info_box>.info_text_area{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
}
/* 标题 */
.photograph_bottom_box .info_title{
    font-size: 25px;
}
/* 副标题 */
.photograph_bottom_box .info_subtitle{
    width: 210px;
    margin-top: 10px;
}
/* 价格区域 */
.photograph_bottom_box .info_box>.price{
    position: absolute;
    bottom: 15px;
    left: 20px;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
}
/* 热门 */
.phototheme_hot{
    position: absolute;
    top: 0;
    right: 0;
}
</style>
